<template>
	<header
		class="header-main"
		:class="{'header-main_hidden': headerIsHidden, 'not-index': !isIndex}"
	>
		<div class="container-base">
			<div class="header-main__top">
				<div class="header-main__top_left">
					<div
						class="header-main__top-item"
						@click="togglePopupHeader"
					>
						<span class="header-main__nav-item">Ваш город:</span>
						<span class="header-main__nav-city">
							{{ currentCity || '' }}
						</span>
						<svg
							width="11"
							height="7"
							fill="transparent"
							class="header-main-top__item-chevron"
						>
							<use xlink:href="#chevron-white"></use>
						</svg>
					</div>
					<div
						class="header-main__top-item"
						v-if="!currentBasket?.collective"
					>
						<router-link
							to="/coverage"
							class="header-main__nav-item"
						>География покрытия
						</router-link>
					</div>
				</div>

				<div class="header-main__top_right">
					<div class="header-main__top-item">
						<a
							href="/about/"
							class="header-main__nav-item"
						>О компании</a>
					</div>
					<div class="header-main__top-item">
						<a
							href="/about/delivery/"
							class="header-main__nav-item"
						>Доставка и оплата</a>
					</div>
					<div class="header-main__top-item">
						<a
							href="/about/bonus/"
							class="header-main__nav-item"
						>Скидки и баллы</a>
					</div>
				</div>
			</div>
			<div class="header-main__bottom">
				<div class="header-main__bottom_left">
					<div
						class="header-main__logo"
						@click="goToMain()"
					/>
				</div>

				<div
					class="header-main__bottom_center"
					v-if="!isIndex"
				>
					<SearchMain
						compact
						class="header-main__search"
					/>
				</div>

				<div class="header-main__bottom_right">
					<router-link
						class="header-main__bottom-item"
						to="/cart"
					>
						<div class="header-main__bottom-icon-wrapper">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								class="header-main__bottom-icon"
							>
								<path
									fill="#fff"
									fill-rule="evenodd"
									d="M16 10.7c-.7 0-1.3.6-1.3 1.3a2.7 2.7 0 0 1-5.4 0 1.3 1.3 0 1 0-2.6 0 5.3 5.3 0 0 0 10.6 0c0-.7-.6-1.3-1.3-1.3Zm4 10.6H4c-.7 0-1.3-.6-1.3-1.3V8h18.6v12c0 .7-.6 1.3-1.3 1.3ZM6.8 3.1c.3-.3.6-.4 1-.4h8.4c.4 0 .7.1 1 .4l2.2 2.2H4.6l2.2-2.2Zm16 1.8-3.7-3.7A4 4 0 0 0 16.2 0H7.8C6.7 0 5.7.4 4.9 1.2L1.2 4.9A4 4 0 0 0 0 7.8V20a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V7.8c0-1.1-.4-2.1-1.2-2.9Z"
									clip-rule="evenodd"
								/>
							</svg>

							<div
								class="header-main__bottom-notify"
								v-if="cartProductCount"
							>{{ cartProductCount }}
							</div>
						</div>

						<span class="header-main__bottom-name">Корзина</span>
					</router-link>
					<div class="header-main__bottom-auth">
						<button
							class="header-main__bottom-enter"
							type="button"
							@click="$store.commit('openPhoneAuthPopup')"
							v-if="!$store.state.user"
						> Войти
						</button>
						<div
							v-if="$store.state.user"
							class="header-main__bottom-photo-wrapper"
							@click="setNavigationPopup(true)"
						>
							<div class="header-main__bottom-photo">
								<img
									:src="user.PERSONAL_PHOTO"
									alt="user photo"
									class="header-main__bottom-user"
									v-if="user.PERSONAL_PHOTO"
								>
								<div
									class="header-main__bottom-user_placeholder"
									v-else
								>
									{{
										this.user?.NAME?.[0]?.toUpperCase() ||
										this.user?.EMAIL?.[0]?.toUpperCase() || 'В&Б'
									}}
								</div>
							</div>
							<div class="scores">
								<span class="scores__text">На счете баллов:</span>
								<div class="scores__token">
									<span class="scores__amount">{{ this.user.BALLS }}</span>
									<svg
										class="scores__icon"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM7.67782 3.27344H4.06982V9.00144H6.30182C6.65916 9.00144 6.98716 8.9321 7.28582 8.79344C7.58449 8.65477 7.81916 8.45477 7.98982 8.19344C8.16582 7.92677 8.25382 7.61477 8.25382 7.25744C8.25382 6.9001 8.16582 6.59077 7.98982 6.32944C7.81916 6.0681 7.58449 5.87077 7.28582 5.73744C6.99249 5.59877 6.66449 5.52944 6.30182 5.52944H5.14982V4.26544H7.67782V3.27344ZM6.94982 7.79344C6.80049 7.9321 6.60049 8.00144 6.34982 8.00144H5.14982V6.51344H6.34982C6.61116 6.51344 6.81382 6.58277 6.95782 6.72144C7.10182 6.8601 7.17382 7.03877 7.17382 7.25744C7.17382 7.47077 7.09916 7.64944 6.94982 7.79344Z"
											fill="white"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<portal>
				<Popup
					:visible.sync="authFormPopup"
					:onlyClosePopup="true"
					@closePopup="closeAuthPopup"
				>
					<AuthForm class="header__auth-form"/>
				</Popup>
			</portal>
			<pointed-modal
				point=".header-main__bottom_right"
				v-if="navigationPopup"
				:leftAlign="11"
				:topAlign="0"
				:fixed="true"
				:modalClass="['auth-modal']"
			>
				<Authed/>
			</pointed-modal>
			<Popup :visible.sync="isPopupHeader">
				<CitySelect
					:isPopupHeader="isPopupHeader"
					@closeCitySelect="closeCitySelect"
				/>
			</Popup>
		</div>
		<div
			class="header__nav-mobile_icon"
			@click="toggleMobMenu"
		>
			<svg
				width="24"
				height="16"
				viewBox="0 0 24 16"
				fill="none"
				class="header__nav-mobile_icon_burger"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M1.264 0H22.7347C23.4307 0 24 0.569333 24 1.26533V1.40133C24 2.09733 23.4307 2.66667 22.7347 2.66667H1.264C0.568 2.66667 0 2.09733 0 1.40133V1.26533C0 0.569333 0.568 0 1.264 0ZM22.7347 6.66667H1.264C0.568 6.66667 0 7.236 0 7.932V8.068C0 8.764 0.568 9.33333 1.264 9.33333H22.7347C23.4307 9.33333 24 8.764 24 8.068V7.932C24 7.236 23.4307 6.66667 22.7347 6.66667ZM22.7347 13.3333H1.264C0.568 13.3333 0 13.9027 0 14.5987V14.7347C0 15.4307 0.568 16 1.264 16H22.7347C23.4307 16 24 15.4307 24 14.7347V14.5987C24 13.9027 23.4307 13.3333 22.7347 13.3333Z"
				/>
			</svg>
		</div>
		<ConfirmationPopup
			:visible="geoPopup"
			subtitle="Для удобства использования нашим сервисом мы просим разрешение на доступ к вашей геолокации,
			 это позволит нам предложить вам ближайшие рестораны и улучшить качество обслуживания!"
			@closePopup="handleCloseGeoPopup"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn"
					@click="handleAcceptGeo();"
				>Далее
				</button>
			</template>
		</ConfirmationPopup>
	</header>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import PointedModal from "../modal/PointedModal.vue";
import SearchMain from "../form/SearchMain.vue";
import CitySelect from "@/components/popup/CitySelect";
import search from "@/mixins/search";
import {loadYmap} from "vue-yandex-maps";

export default {
	components: {
		PointedModal,
		SearchMain,
		CitySelect,
		Authed: () => import("@/components/form/Authed"),
		AuthForm: () => import("@/components/form/auth/AuthForm"),
		ConfirmationPopup: () => import("@/components/popup/ConfirmationPopup"),
	},
	data: () => ({
		previousScrollValue: 0,
		headerIsHidden: false,
		isPopupHeader: false,
		geoPopup: false,
	}),
	computed: {
		...mapGetters({
			user: "getUser",
			cartProductsCount: 'getCartProductsCount',
			myCartDetails: 'getMyCartDetails',
			currentBasket: 'getCurrentBasket',
			currentCity: "getCurrentCity",
			authFormPopup: "getAuthFormPopup",
			navigationPopup: "getNavigationPopup",
			cities: "getCities"
		}),
		cartProductCount()
		{
			return this.currentBasket?.id ? this.myCartDetails.count : this.cartProductsCount
		},
		isIndex()
		{
			return this.$route.path === "/" || this.$route.path === "/auth/";
		},
	},
	mixins: [search],
	methods: {
		...mapActions({
			isLogged: "isLogged",
			getCurrentCity: "getCurrentCity",
			changeCity: "changeCity",
			getCityList: "getCityList",
			closeAuthPopup: "closeAuthPopup",
		}),
		...mapMutations({
			setNavigationPopup: "setNavigationPopup",
		}),
		togglePopupHeader()
		{
			if (this.currentBasket?.collective) return;

			this.isPopupHeader = !this.isPopupHeader;
		},
		closeCitySelect(value)
		{
			this.isPopupHeader = value;
		},
		goToMain()
		{
			this.$cookie.delete("basketId");
			this.$router.push('/');
		},
		toggleMobMenu(e)
		{
			this.$store.state.mobMenuState ? this.$store.commit("hideMobMenu") : this.$store.commit("showMobMenu");
		},

		toggleHeaderVisible()
		{
			if (window.innerWidth > 990)
			{
				if (this.$store.state.auth.form)
				{
					this.headerIsHidden = false;
					return;
				}

				const windowHeight = window.pageYOffset;

				(windowHeight < this.previousScrollValue) || (windowHeight <= 120)
					? this.headerIsHidden = false
					: this.headerIsHidden = true;

				this.previousScrollValue = windowHeight;
			}
		},
		handleScrollEvent()
		{
			this.toggleHeaderVisible();
		},
		getCurrentGeolocation()
		{
			return new Promise((resolve, reject) =>
			{
				if (navigator.geolocation)
				{
					navigator.geolocation.getCurrentPosition((position) =>
					{
						const lat = position.coords.latitude;
						const lon = position.coords.longitude;

						resolve({lat, lon});
						return {lat, lon};
					}, () =>
					{
						this.isPopupHeader = true;

						resolve({lat: null, lon: null});
						return {lat: null, lon: null};
					});
				} else
				{
					resolve({lat: null, lon: null});
					return {lat: null, lon: null};
				}
			});
		},
		async getCityByCoords({lat, lon})
		{
			if (!lat || !lon) return '';

			await loadYmap({
				apiKey: process.env.VUE_APP_YA_API_KEY,
				suggestApiKey: process.env.VUE_APP_YA_SUGGEST_API_KEY,
				lang: 'ru_RU',
				coordorder: 'latlong',
				version: '2.1'
			});

			const {geoObjects} = await ymaps.geocode([lat, lon], {kind: "locality"});

			return geoObjects.get(0).getLocalities()?.[0] || ''
		},
		async selectCity(myCityName)
		{
			if (!myCityName) return await this.getCurrentCity();

			const currentCity = this.cities.find(el => el.UF_NAME.trim() === myCityName.trim());

			if (!currentCity) return await this.getCurrentCity();

			await this.changeCity(currentCity);
		},
		async handleCloseGeoPopup()
		{
			this.geoPopup = false;
			await this.selectCity();
		},
		async handleAcceptGeo()
		{
			this.geoPopup = false;

			const {lat, lon} = await this.getCurrentGeolocation();
			const myCityName = await this.getCityByCoords({lat, lon});
			await this.selectCity(myCityName);
		},
		async openGeoPopup()
		{
			if (this.$route.query.token) return await this.selectCity();

			if (localStorage.getItem("geoReceived")) return await this.selectCity();

			localStorage.setItem("geoReceived", 'true');

			this.geoPopup = true;
		}
	},
	/**
	 * Достаем список городов и выбранный город
	 * и ставим значения со стора если они есть
	 */
	async mounted()
	{
		await this.isLogged();

		await this.getCityList();

		this.openGeoPopup();

		window.addEventListener("scroll", this.handleScrollEvent, {passive: true});
	},
	beforeDestroy()
	{
		window.removeEventListener("scroll", this.handleScrollEvent);
	},

	watch:
		{
			headerIsHidden(val)
			{
				this.$store.commit("ui/SET_HEADER_VISIBILITY", !val);
			},
		},
};
</script>
<style lang="scss">
.pointed-modal.auth-modal
{padding: 8px 22px;}

.header__auth-form
{
	padding: 44px;
	width: 400px;
}

.scores
{
	margin-left: 12px;

	&__text
	{
		font-family: $mainFontMediumNew;
		font-weight: 500;
		font-size: 14px;
		line-height: 15px;
		color: #fff;
		margin-bottom: 3px;
		display: inline-block;
		max-width: 60px;
	}

	&__token
	{
		padding: 0 7px 0 4px;
		background: $lightYellow;
		color: #fff;
		display: inline-flex;
		align-items: center;
		border-radius: 4px;
		white-space: nowrap;
		height: 22px;
		vertical-align: middle;
	}

	&__amount
	{
		font-family: $mainFontBoldNew;
		font-size: 16px;
		line-height: 18px;
		font-weight: bold;
		margin-right: 4px;
		white-space: nowrap;
	}

	&__icon
	{
		height: 12px;
		width: 12px;
	}
}


.header-main
{
	// Исправление отрисовки шрифта - слипание букв
	letter-spacing: .4px;

	background: $green;
	padding: 5px 25px 16px 25px;
	position: sticky;
	transition: transform .2s ease-in-out;
	top: 0;
	z-index: 3;

	&_hidden
	{transform: translateY(-100%);}

	&__search
	{
		padding: 0;
		margin: 0;
		margin-top: 6px;
	}

	& .header__nav-mobile_icon
	{
		display: none;
		position: absolute;
		cursor: pointer;
		z-index: 10;

		&_burger
		{fill: #fff;}
	}

	&__top
	{
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		flex-wrap: wrap;

		&_left, &_right
		{display: flex;}

		&-title
		{
			font-size: 18px;
			line-height: 22px;
			color: #fff;
			font-family: "Montserrat-Bold", "Montserrat-Bold-fb", "PT Sans", sans-serif;
			margin-bottom: 40px;
			width: 100%;
		}

		&-item
		{
			&:not(:last-child)
			{margin-right: 24px;}

			cursor: pointer;
			font-size: 14px;
			line-height: 24px;
		}

		&-cities
		{
			width: 100%;
			background-color: transparent;
			top: 40px;
			left: 0;
			padding-top: 0;
			padding-bottom: 0;
			max-height: 0;
			overflow: hidden;
			opacity: 0;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&-list
			{
				display: flex;

				&-item
				{
					&:not(:last-child)
					{
						display: inline-block;
						margin-right: 20px;
					}
				}
			}

			&_active
			{
				opacity: 1;
				padding-top: 29px;
				padding-bottom: 40px;
				max-height: 272px;
			}
		}
	}

	&__bottom
	{
		display: flex;
		justify-content: space-between;

		&_center
		{
			width: 100%;
			max-width: 100%;
		}

		&-notify
		{
			padding: 1px 4px;
			background: $red;
			font-family: $mainFontBoldNew;
			font-size: 12px;
			line-height: 14px;
			font-weight: bold;
			color: #fff;
			position: absolute;
			top: -3px;
			border-radius: 50%;
			right: 0;
			transform: translateX(50%);
		}

		&-photo
		{
			width: 56px;
			height: 56px;
			border: 2px solid #fff;
			padding: 4px;
			border-radius: 50%;
			flex-shrink: 0;

			&-wrapper
			{
				display: flex;
				cursor: pointer;
				justify-content: space-between;
				width: 140px;
			}
		}

		&-user
		{
			width: 100%;
			height: auto;
			border-radius: 50%;

			&_placeholder
			{
				color: #fff;
				font-size: 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				font-weight: bold;

				&_green
				{color: $green;}
			}
		}

		&-enter
		{
			font-family: $mainFontBoldNew;
			background: #63D76E;
			border-radius: 8px;
			color: #fff;
			font-size: 16px;
			padding: 19px 36px;
			border: none;
			outline: none;
			font-weight: 700;
			transition: all .2s ease-in-out;
			width: 140px;

			&:hover
			{background: #85DF8D;}
		}

		&-name
		{
			font-family: $mainFontMediumNew;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
		}

		&-icon
		{
			position: relative;
			height: 25px;
			width: 25px;
			margin-bottom: 10px;
			margin-top: 3px;

			&-wrapper
			{
				display: flex;
				position: relative;
			}
		}

		&_right
		{
			display: flex;
			align-items: center;
		}

		&-item
		{
			max-height: 56px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;
			position: relative;

			&:not(:last-child)
			{margin-right: 32px;}
		}
	}

	&__nav
	{
		&-item
		{
			color: #fff;
			opacity: 0.6;
			transition: opacity .2s ease-in-out;

			&:hover
			{opacity: 1;}
		}

		&-city
		{
			font-family: $mainFontBoldNew;
			color: #fff;
			font-weight: bold;
			font-size: 14px;
			margin: 0 7px;
		}
	}

	&__logo
	{
		display: block;
		cursor: pointer;
		width: 200px;
		margin-top: 0;
		height: 66px;
		background: url("~@/assets/img/logo-white.svg") no-repeat;
		background-size: contain;
	}
}

@media (max-width: 990px)
{
	.header__auth-form
	{width: 100%;}

	.header-main.not-index
	{padding-bottom: 86px !important;}

	.scores
	{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		&__text
		{
			color: $textColor;
			font-size: 14px;
			text-transform: none;
		}
	}

	.header-main
	{
		padding: 25px 0 0 0;
		position: relative;

		&__logo
		{
			width: 176px;
			height: 58px;
			margin-bottom: 5px;
		}

		&__top
		{display: none;}

		&__nav-mobile_icon
		{display: block;}

		&__bottom
		{
			&-photo
			{
				border-color: $greyBorder;
				flex: 1 0 auto;

				&-wrapper
				{
					width: auto;
					margin-bottom: 16px;
				}
			}

			&-enter, &-name
			{display: none;}

			&_center
			{
				position: absolute;
				top: 72px;
				max-width: 100%;
				width: unset;
			}

			&_right
			{margin-right: 40px;}
		}

		& .header
		{
			&__nav-mobile_icon
			{
				display: block;
				top: 44px;
				right: 44px;
			}
		}
	}
}

@media (max-width: 576px)
{
	.header-main
	{
		padding-top: 16px;

		&__logo
		{
			width: 175px;
			height: 58px;
			margin-bottom: 0;
		}

		& .header
		{
			&__nav-mobile_icon
			{
				top: 36px;
				right: 36px;
			}
		}

		& .container
		{padding: 0 32px;}

		&__bottom
		{

			&_right
			{display: none;}
		}
	}
}

// Экран <= 374px
@media (max-width: 374px)
{
	.header-main
	{
		padding-top: 12px;

		&__logo
		{
			display: block;
			width: 140px;
			margin-top: 0;
			height: 46px;
		}

		& .header
		{
			&__nav-mobile_icon
			{
				top: 26px;
				right: 28px;
			}
		}
	}
}


</style>
